import React, { useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { hl7Instance, ssoInstance, dbInstance } from "../../api/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import "./signup.scss";
import sliderbg from "../../assets/img/logo/freedom/background.png";
import poweredby from "../../assets/img/powered-by-aaneel.svg";
import memberIDCard from "../../assets/img/logo/freedom/id-card.png";
import freedomLogo from "../../assets/img/logo/freedom/logo.svg";
import healthSunLogo from "../../assets/img/logo/healthsun/logo.svg";
import optimumLogo from "../../assets/img/logo/Optimum/logo.svg";
import optimumBg from "../../assets/img/logo/Optimum/optimum-bg-1.png";

import  { useAuth } from "../../context/auth-context";
import { formatDate,getCurrentYear,InputformatDate} from "../../utils/Utils";
const schema = yup.object().shape({
  FirstName: yup.string().required("First name is required"),
  LastName: yup.string().required("Last name is required"),
  Email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .required("Email is required"),
  Username: yup
    .string()
    .required("Username is required")
    .matches(
      /^[a-zA-Z0-9._-]+$/,
      "Username can only contain letters, numbers, underscores, and periods."
    ),
  Password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must not exceed 20 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain an uppercase letter, lowercase letter, a number, and a special character"
    )
    .test(
      "no-personal-info",
      "Password cannot include your first name or username.",
      function (value) {
        const { FirstName, Username } = this.parent;
        if (value) {
          return !value.includes(FirstName) && !value.includes(Username);
        }
        return true;
      }
    )
    .required("Password is required"),
  ConfirmPassword: yup.string().required("Confirm Password is required"),
  DOB: yup.date().required("Date of birth is required"),
  PIN: yup.string().required("PIN is required"),
});
const getCurrentDateTime = () => {
  const date = new Date();
  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
    date.getHours()
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
    date.getSeconds()
  ).padStart(2, "0")}`;
  return formattedDate; // Example Output: "2023-10-20 13:15:30"
};
const getAccessToken = async () => {
  try {
    const params = new URLSearchParams({
      grant_type: "client_credentials",
      client_id: "FhirApiAdmin",
      client_secret: "ED3B48D2-6EDF-4293-B3E5-3386730E95B3",
    });
    const response = await hl7Instance.post(
      `/AadSmartOnFhirProxy/token`,
      params,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data.access_token;
  } catch (error) {
  }
};

function SignUpForm() {
  const [formData, setFormData] = useState({
    ID: uuidv4(),
    FirstName: "",
    LastName: "",
    Username: "",
    Email: "",
    Password: "",
    ConfirmPassword: "",
    DOB: "",
    PIN: "",
    MemberID: "",
    MedicareID: "",
    Clients: "",
  });
  const [isStep2Visible, setStep2Visible] = useState(false);
  const [alertMessages, setAlertMessages] = useState([]);
  const { userManager } = useAuth();
  const [loading, setLoading] = useState(false);
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);

  const fetchPatientRecord = async (identifier) => {
    setLoading(true);
    const token = await getAccessToken();
    try {
      const response = await hl7Instance.get(
        `/Patient?identifier=https://terminology.hl7.org/resources/variables/member-registration-pin|${identifier}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (
        response.data.entry &&
        response.data.entry.length > 0 &&
        response.data.entry[0].resource &&
        response.data.entry[0].resource.id
      ) {
        const memberId = response.data.entry[0].resource.id;
        setFormData((prevState) => ({
          ...prevState,
          MemberID: memberId,
        }));
      }
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error(
        "Error fetching the patient record:",
        error.response ? error.response.data : error.message
      );
    }
  };
  const handleNextClick = async (e) => {
    e.preventDefault();
    const supportNumber = process.env.REACT_APP_Support_CellNumber;
    let isValid = true;
    let newErrors = {};
    if (!formData.FirstName) {
      isValid = false;
      newErrors.FirstName = "First Name is required!";
    }
    if (!formData.LastName) {
      isValid = false;
      newErrors.LastName = "Last Name is required!";
    }
    if (!formData.DOB) {
      isValid = false;
      newErrors.DOB = "Date of Birth is required!";
    }
    if (!formData.PIN) {
      isValid = false;
      newErrors.PIN = "PIN is required!";
    }
    if (isValid) {
      const identifier = formData.PIN;
      const patientRecord = await fetchPatientRecord(identifier);
      if (patientRecord.total > 0) {
        const patientDetails = patientRecord.entry[0].resource;
        const firstNameFromDb = patientDetails.name[0].given[0];
        const dbDOB = formatDate(patientDetails.birthDate);
        const inputDOB = InputformatDate(formData.DOB);
        if (
          patientDetails.name[0].family.toLowerCase() ===
            formData.LastName.toLowerCase() &&
          firstNameFromDb.toLowerCase() === formData.FirstName.toLowerCase() &&
          dbDOB === inputDOB
        ) {
          setStep2Visible(true);
          setAlertMessages([]);
        } else {
          setAlertMessages([
            `First Name, Last Name, or DOB does not match our records! Please call on ${supportNumber}`,
          ]);
        }
      } else {
        setAlertMessages([
          `PIN Number does not match our records! Please call on ${supportNumber}`,
        ]);
      }
    } else {
      setErrors((prevState) => ({ ...prevState, ...newErrors }));
    }
  };
  const handleBackClick = () => {
    setStep2Visible(false);
  };
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const insertMember = async (userData) => {
    try {
      const requestData = {
        SSOGuid: userData.ID,
        FirstName: userData.FirstName,
        LastName: userData.LastName,
        Username: userData.Username,
        Email: userData.Email,
        DOB: userData.DOB,
        PIN: userData.PIN,
        MemberID: userData.MemberID,
        RowStatus: "A",
        AddDate: getCurrentDateTime(),
        BB_IsActive: "No",
        GivenName: `${userData.FirstName}, ${userData.LastName}`,
      };
      const response = await dbInstance.post("/createmember/", requestData);
      if (response.status === 201) {
        setLoading(false);
        navigate("/");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error inserting the member:", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    schema
      .validateAt(name, { ...formData, [name]: value })
      .then(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
        }));
        if (name === "Password" || name === "ConfirmPassword") {
          if (updatedData.Password !== updatedData.ConfirmPassword) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              ConfirmPassword: "Passwords must match",
            }));
          }
        }
      })
      .catch((err) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: err.message,
        }));
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAgreed) {
      setShowAgreementModal(true);
      return;
    }
    setLoading(true);
    try {
      await schema.validate(formData, { abortEarly: false });
      const { MemberID, ConfirmPassword, DOB, ...restOfData } = formData;
      const requestData = {
        ...restOfData,
        Clients: [`${process.env.REACT_APP_SSO_CLIENTS}`],
        EmailConfirmed: true,
        FullName: `${restOfData.FirstName}, ${restOfData.LastName}`,
        IsEnabled: true,
      };
      const ssoResponse = await ssoInstance.post(
        "api/user/CreateApplicationUserWithPassword",
        requestData
      );
      if (ssoResponse.status === 200 && ssoResponse.data.succeeded) {
        insertMember({ ...formData });
      } else if (ssoResponse.status === 200 && !ssoResponse.data.succeeded) {
        setLoading(false);
        const errorMessages = ssoResponse.data.errors.map(
          (error) => error.description
        );
        setAlertMessages(errorMessages);
      } else {
        setLoading(false);
        throw new Error("SSO Registration failed.");
      }
    } catch (err) {
      setLoading(false);
      const errorMessages = [];
      if (err instanceof yup.ValidationError) {
        err.inner.forEach((validationError) => {
          errorMessages.push(validationError.message);
        });
      } else if (
        err.response &&
        err.response.data &&
        Array.isArray(err.response.data)
      ) {
        const passwordError = err.response.data.find(
          (e) => e.code === "Password change error"
        );
        if (passwordError) {
          errorMessages.push(passwordError.description);
        }
      } else {
        errorMessages.push(err.message || "An unknown error occurred.");
      }
      setAlertMessages(errorMessages);
    }
  };
  const clientName = process.env.REACT_APP_ClientName;
  const getLogoSource = () => {
    switch (clientName) {
      case "freedom":
        return `${freedomLogo}`;
      case "healthsun":
        return `${healthSunLogo}`;
      case "optimum":
        return `${optimumLogo}`;
      default:
        return "";
    }
  };
  const getBgSource = () => {
    if (clientName === "freedom" || clientName === "healthsun") {
      return `${sliderbg}`;
    } else {
      return `${optimumBg}`;
    }
  };
  const [hidden, setHidden] = React.useState(false);
  const handleLoginClick = () => {
    userManager.signinRedirect().catch((error) => {
      console.error("SSO login error:", error);
    });
  };
  const togglePassword = () => {
    setPasswordToggle(!passwordToggle);
  };
  const toggleConfirmPassword = () => {
    setConfirmPasswordToggle(!confirmPasswordToggle);
  };
  return (
    <>
      <div className="d-flex justify-content-center align-items-center authentication-main">
        <div className="d-flex authentication-card">
          <div className="authentication-left">
            <img src={getBgSource()} alt="Slider" />
          </div>
          <div className="authentication-right">
            <div className="authentication-header d-flex justify-content-sm-between align-items-sm-center flex-column flex-sm-row">
              <div className="d-flex flex-column authentication-title">
                <h1>Sign up</h1>
                <span>Insert your information to signup</span>
              </div>
              <div className="authentication-logo">
                <img src={getLogoSource()} alt="Freedom Health" />
              </div>
            </div>
            <div className="stepper d-flex">
              <div
                className={`step d-flex ${
                  !isStep2Visible ? "active" : "success"
                }`}
              >
                {!isStep2Visible && <span>1</span>}
                {isStep2Visible && (
                  <span>
                    <i className="fh_check_done"></i>
                  </span>
                )}
                <label>Personal Info</label>
              </div>
              <div
                className={`step d-flex ${
                  isStep2Visible ? "active" : "disabled"
                }`}
              >
                <span>2</span>
                <label>Credentials Info</label>
              </div>
            </div>
            <div className="form-content">
              {loading && (
                <div className="text-center my-4">
                  <div className="spinner-border" role="status"></div>
                </div>
              )}
              {alertMessages && alertMessages.length > 0 && (
                <div className="alert alert-danger" role="alert">
                  {alertMessages.map((message, index) => (
                    <div key={index}>{message}</div>
                  ))}
                </div>
              )}
              <form
                onSubmit={handleSubmit}
                className="needs-validation"
                noValidate
              >
                {!isStep2Visible && (
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="FirstName" className="d-flex form-label">
                        First Name <span>*</span>
                      </label>
                      <div className="form-field position-relative">
                        <div className="position-relative">
                          <input
                            type="text"
                            className={`form-control h-48 ${
                              errors.FirstName
                                ? "is-invalid"
                                : formData.FirstName
                                ? "is-valid"
                                : ""
                            }`}
                            id="FirstName"
                            name="FirstName"
                            value={formData.FirstName}
                            onChange={handleChange}
                            required
                          />
                          <span className="field-icon position-absolute top-50 translate-middle-y">
                            <i className="fh_user_fill"></i>
                          </span>
                        </div>
                        <div
                          className={`invalid-feedback ${
                            errors.FirstName ? "invalid-error" : ""
                          }`}
                        >
                          {errors.FirstName}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="LastName" className="d-flex form-label">
                        Last Name <span>*</span>
                      </label>
                      <div className="form-field position-relative">
                        <div className="position-relative">
                          <input
                            type="text"
                            className={`form-control h-48 ${
                              errors.LastName
                                ? "is-invalid"
                                : formData.LastName
                                ? "is-valid"
                                : ""
                            }`}
                            id="LastName"
                            name="LastName"
                            value={formData.LastName}
                            onChange={handleChange}
                            required
                          />
                          <span className="field-icon position-absolute top-50 translate-middle-y">
                            <i className="fh_user_fill"></i>
                          </span>
                        </div>
                        <div
                          className={`invalid-feedback ${
                            errors.LastName ? "invalid-error" : ""
                          }`}
                        >
                          {errors.LastName}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <label htmlFor="DOB" className="d-flex form-label">
                            Date of Birth <span>*</span>
                          </label>
                          <div
                            className={`form-field ${
                              errors.DOB
                                ? "is-invalid"
                                : formData.DOB
                                ? "is-valid"
                                : ""
                            }`}
                          >
                            <DatePicker
                              className={`form-control h-48 ${
                                errors.DOB
                                  ? "is-invalid"
                                  : formData.DOB
                                  ? "is-valid"
                                  : ""
                              }`}
                              placeholderText="MM / DD / YYYY"
                              selected={formData.DOB}
                              onChange={(date) =>
                                handleChange({
                                  target: { value: date, name: "DOB" },
                                })
                              }
                              maxDate={new Date()}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              dateFormat="dd / MM / yyyy"
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.DOB ? "invalid-error" : ""
                              }`}
                            >
                              {errors.DOB}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12 position-relative">
                          <div
                            className={`member-id-card ${
                              hidden ? "element-visible" : "element-hidden"
                            }`}
                          >
                            <div className="d-flex justify-content-between member-id-header">
                              <p>
                                <span className="red">
                                  <b>*</b>
                                </span>{" "}
                                For Sign-up please enter your{" "}
                                <b>
                                  <span className="primary">full</span> Member
                                  ID Number
                                </b>{" "}
                                and <br />
                                <b>
                                  <span className="red">Last 4 Digit</span> of
                                  Medicare ID Number
                                </b>{" "}
                                from your Insurance Card.
                              </p>
                              <span onClick={() => setHidden(!hidden)}>
                                <i className="fh_close"></i>
                              </span>
                            </div>
                            <div className="member-id-img">
                              <img src={memberIDCard} alt="Member ID Card" />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-center form-label-main">
                            <label htmlFor="PIN" className="d-flex form-label">
                              PIN <span>*</span>
                            </label>
                            <span
                              className="information-icon"
                              onClick={() => setHidden(!hidden)}
                            >
                              <u>Add a PIN?</u>{" "}
                              <i className="fh_error_line"></i>
                            </span>
                          </div>
                          <div className="form-field position-relative">
                            <input
                              type="text"
                              className={`form-control h-48 ${
                                errors.PIN
                                  ? "is-invalid"
                                  : formData.PIN
                                  ? "is-valid"
                                  : ""
                              }`}
                              id="PIN"
                              name="PIN"
                              value={formData.PIN}
                              onChange={handleChange}
                              onClick={() => setHidden(!hidden)}
                              required
                            />
                            <div
                              className={`invalid-feedback ${
                                errors.PIN ? "invalid-error" : ""
                              }`}
                            >
                              {errors.PIN}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 form-action mt-0">
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <button
                            type="button"
                            className="btn btn-border-primary w-100 h-48"
                            onClick={handleLoginClick}
                          >
                            <i className="fh_arrow_left icon-mr"></i> Back to
                            login
                          </button>
                        </div>
                        <div className="col-sm-6 col-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-100 h-48"
                            onClick={handleNextClick}
                          >
                            Next <i className="fh_arrow_right icon-ml"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isStep2Visible && (
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="Username" className="d-flex form-label">
                        Username <span>*</span>
                      </label>
                      <div className="form-field position-relative">
                        <div className="position-relative">
                          <input
                            type="text"
                            className={`form-control h-48 ${
                              errors.Username
                                ? "is-invalid"
                                : formData.Username
                                ? "is-valid"
                                : ""
                            }`}
                            id="Username"
                            name="Username"
                            value={formData.Username}
                            onChange={handleChange}
                            required
                          />
                          <span className="field-icon position-absolute top-50 translate-middle-y">
                            <i className="fh_user_fill"></i>
                          </span>
                        </div>
                        <div
                          className={`invalid-feedback ${
                            errors.Username ? "invalid-error" : ""
                          }`}
                        >
                          {errors.Username}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="Email" className="d-flex form-label">
                        E-Mail Address <span>*</span>
                      </label>
                      <div className="form-field position-relative">
                        <div className="position-relative">
                          <input
                            type="email"
                            className={`form-control h-48 ${
                              errors.Email
                                ? "is-invalid"
                                : formData.Email
                                ? "is-valid"
                                : ""
                            }`}
                            id="Email"
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                            required
                          />
                          <span className="field-icon position-absolute top-50 translate-middle-y">
                            <i className="fh_email_fill"></i>
                          </span>
                        </div>
                        <div
                          className={`invalid-feedback ${
                            errors.Email ? "invalid-error" : ""
                          }`}
                        >
                          {errors.Email}
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <label
                            htmlFor="Password"
                            className="d-flex form-label"
                          >
                            Password <span>*</span>
                          </label>
                          <div className="form-field position-relative">
                            <div className="position-relative">
                              <input
                                type={passwordToggle ? "text" : "password"}
                                className={`form-control h-48 ${
                                  errors.Password
                                    ? "is-invalid"
                                    : formData.Password
                                    ? "is-valid"
                                    : ""
                                }`}
                                id="Password"
                                name="Password"
                                placeholder=""
                                value={formData.Password}
                                onChange={handleChange}
                                required
                              />
                              <span
                                className="field-icon position-absolute top-50 translate-middle-y"
                                onClick={togglePassword}
                              >
                                <i
                                  className={
                                    passwordToggle
                                      ? "fh_eye_off_line"
                                      : "fh_eye_fill"
                                  }
                                ></i>
                              </span>
                            </div>
                            <div
                              className={`invalid-feedback ${
                                errors.Password ? "invalid-error" : ""
                              }`}
                            >
                              {errors.Password}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <label
                            htmlFor="ConfirmPassword"
                            className="d-flex form-label"
                          >
                            Confirm Password <span>*</span>
                          </label>
                          <div className="form-field position-relative">
                            <div className="position-relative">
                              <input
                                type={
                                  confirmPasswordToggle ? "text" : "password"
                                }
                                className={`form-control h-48 ${
                                  errors.ConfirmPassword
                                    ? "is-invalid"
                                    : formData.ConfirmPassword
                                    ? "is-valid"
                                    : ""
                                }`}
                                id="ConfirmPassword"
                                name="ConfirmPassword"
                                value={formData.ConfirmPassword}
                                onChange={handleChange}
                                required
                              />
                              <span
                                className="field-icon position-absolute top-50 translate-middle-y"
                                onClick={toggleConfirmPassword}
                              >
                                <i
                                  className={
                                    confirmPasswordToggle
                                      ? "fh_eye_off_line"
                                      : "fh_eye_fill"
                                  }
                                ></i>
                              </span>
                            </div>
                            <div
                              className={`invalid-feedback ${
                                errors.ConfirmPassword ? "invalid-error" : ""
                              }`}
                            >
                              {errors.ConfirmPassword}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 form-action">
                      <div className="row">
                        <div className="col-sm-12 col-12 d-flex justify-content-start align-items-start">
                          <input
                            type="checkbox"
                            className="form-check-input me-3 flex-shrink-0"
                            id="termsCheckbox"
                            checked={isAgreed}
                            onChange={(e) => setIsAgreed(e.target.checked)}
                          />
                          <label
                            className="form-check-label mt-1"
                            htmlFor="termsCheckbox"
                          >
                            I have read and agree to the{" "}
                            <a
                              href="https://www.aaneel.com/application-privacy-policy"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a
                              href="https://www.aaneel.com/application-terms-of-service"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms of Service
                            </a>
                            .
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 form-action">
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <button
                            type="submit"
                            className="btn btn-border-primary w-100 h-48"
                            onClick={handleBackClick}
                          >
                            <i className="fh_arrow_left icon-mr"></i> Back
                          </button>
                        </div>
                        <div className="col-sm-6 col-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-100 h-48"
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
            <div className="authentication-footer d-flex flex-column flex-sm-row justify-content-between align-items-sm-end">
              <p>© {getCurrentYear()} AaNeel Infotech LLC</p>
              <span className="poweredby-image">
                <img src={poweredby} alt="AaNeel PoweredBy" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal ${showAgreementModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: showAgreementModal ? "block" : "none" }}>
        <div className="modal-dialog signup-me-popup" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Agreement Required</h5>
            </div>
            <div className="modal-body">
              <p>
                Please agree to the Privacy Policy and Terms of Service to
                continue.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setShowAgreementModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SignUpForm;