import React, { useState, useEffect, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import { UserAddDateToMMDDYYYY } from "../../utils/Utils";
import { ApplicationApi } from "../../service/Api/ApplicationsApi";
import { boolean } from "yup";

const UserSearch = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [localPageSize, setLocalPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [isPageSizeChange, setIsPageSizeChange] = useState(boolean);
  const [searchCriteria, setSearchCriteria] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    isActive: "",
  });
  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Username",
        accessor: "username",
        Cell: ({ row }) => (
          <span
            className="cursor-pointer primary text-decoration-underline text-capitalize"
            onClick={() => handleRowClick(row.original.userID_PK)} // Assuming 'id' is the unique identifier
          >
            {row.values.username}
          </span>
        ),
      },
      {
        Header: "User Role",
        accessor: "roleID",
        Cell: ({ value }) => (value === 1 ? "Admin" : "Support"),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Created Date",
        accessor: "addDate",
        Cell: ({ value }) =>
          value ? UserAddDateToMMDDYYYY(value) : "No date provided",
      },
      {
        Header: "Is Active",
        accessor: "isActive",
        Cell: ({ value }) => (value === "True" ? "Yes" : "No"),
      },
      {
        Header: "Email Notification",
        accessor: "isCommunicationActive",
        Cell: ({ value }) => (value === true ? "Yes" : "No"),
      },
    ],
    []
  );
  const fetchPaginatedData = async (
    pageIndex,
    pageSize,
    sortBy,
    searchCriteria
  ) => {
    try {
      let sortParam = sortBy.length > 0 ? sortBy[0].id : null;
      let sortDirection = sortBy.length > 0 && sortBy[0].desc ? "DESC" : "ASC";

      let filteredCriteria = {};
      if (searchCriteria) {
        filteredCriteria = Object.entries(searchCriteria).reduce(
          (acc, [key, value]) => {
            if (value) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );
      }
      let queryParams = new URLSearchParams({
        page: pageIndex + 1,
        pageSize: pageSize,
        ...filteredCriteria,
      });

      if (sortParam) {
        queryParams.append("sort", sortParam);
        queryParams.append("sortDirection", sortDirection);
      }

      const response = ApplicationApi.getPortalUserList(
        "",
        "",
        "",
        "",
        "",
        currentPage,
        localPageSize
      );
      return response;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return { data: [], pageInfo: { totalCount: 0 } };
    }
  };
  const navigate = useNavigate();
  const handleRowClick = (userId) => {
    navigate(`/UserDetail/${userId}`);
  };

  const handleCreateNewUser = () => {
    navigate("/UserDetail");
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage, sortBy: [] },
      manualPagination: true,
      manualSortBy: true,
      pageCount: pageCount,
    },
    useSortBy,
    usePagination
  );

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy, searchCriteria }) => {
      setLoading(true);
      try {
        const responseData = await fetchPaginatedData(
          pageIndex,
          pageSize,
          sortBy,
          searchCriteria
        );
        setData(responseData?.items);
        setPageCount(responseData.totalPages);
        await setCurrentPage(responseData?.currentPage);
        setTotalRecords(responseData.totalCount);
        setHasNextPage(responseData.hasNextPage);
        setHasPreviousPage(responseData.hasPreviousPage);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    const isCriteriaCleared = Object.values(searchCriteria).every(
      (value) => value === ""
    );
    if (isCriteriaCleared) {
      fetchData({ pageSize: localPageSize, pageIndex: currentPage, sortBy });
    }
  }, [searchCriteria, sortBy]);

  const handleClear = () => {
    setSearchCriteria({
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      isActive: "",
    });
    setLocalPageSize(10);
  };
  const handleSearchSubmit = async () => {
    setLoading(true);
    try {
      const response = await ApplicationApi.getPortalUserList(
        searchCriteria.firstName,
        searchCriteria.lastName,
        searchCriteria.username,
        searchCriteria.email,
        searchCriteria.isActive
      );
      setData(response.items);
      setTotalRecords(response.totalCount);
      setPageCount(response.totalPages);
      setHasNextPage(response.hasNextPage);
      setHasPreviousPage(response.hasPreviousPage);
    } catch (error) {
      console.error("Error fetching user serach result:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePageSize = async (newPageSize) => {
    // Update the page size immediately without waiting for state change
    setIsPageSizeChange(true);
    setLocalPageSize(newPageSize);

    // Calculate new total pages based on the new page size
    const newTotalPages = Math.ceil(totalRecords / newPageSize);
    let newCurrentPage =
      currentPage >= newTotalPages ? newTotalPages - 1 : currentPage;
    if (isPageSizeChange) {
      newCurrentPage = 1; // Reset to the first page when changing page size
    }

    setCurrentPage(newCurrentPage);

    // Fetch new data with the correct page size
    try {
      const response = await ApplicationApi.getPortalUserList(
        searchCriteria.firstName,
        searchCriteria.lastName,
        searchCriteria.username,
        searchCriteria.email,
        searchCriteria.isActive,
        newCurrentPage,
        newPageSize
      );
      if (response && response.items) {
        setData(response.items); // Update table data
        setTotalRecords(response.totalCount); // Update total records count
        setPageCount(response.totalPages); // Update page count
        setCurrentPage(
          response.currentPage ? response.currentPage : response.pageNumber
        );
        setHasNextPage(response.hasNextPage);
        setHasPreviousPage(response.hasPreviousPage);
      }
    } catch (error) {
      console.error("Error fetching paginated data:", error);
    }
  };

  const handleNext = async () => {
    if (hasNextPage) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage); // Update the current page state
      fetchPageData(nextPage); // Pass the correct next page number to the API
    }
  };

  const handlePrevious = async () => {
    setCurrentPage(currentPage - 1);
    fetchPageData(currentPage - 1);
  };
  const handleGoToFirstPage = async () => {
    setCurrentPage(1);
    fetchPageData(1);
  };
  const handleGoToLastPage = async () => {
    setCurrentPage(pageCount); // Set currentPage to pageCount
    fetchPageData(pageCount); // Fetch data for the last page
  };
  const fetchPageData = async (page) => {
    try {
      const response = await ApplicationApi.getPortalUserList(
        searchCriteria.firstName,
        searchCriteria.lastName,
        searchCriteria.username,
        searchCriteria.email,
        searchCriteria.isActive,
        page,
        localPageSize
      );

      setData(response?.items);
      setHasNextPage(response.hasNextPage);
      setHasPreviousPage(response.hasPreviousPage);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  return (
    <>
      <div className="main-content">
        <div className="content-header d-flex justify-content-between align-items-center">
          <h1 className="page-header">User Search</h1>
          <button
            type="button"
            className="btn btn-primary h-48 d-flex align-items-center"
            onClick={handleCreateNewUser}
          >
            <i className="fh_add icon-mr fs-5"></i>
            <span>Create User</span>
          </button>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="card-form form-content mt-0">
              <div className="row">
                <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4">
                  <label htmlFor="FirstName" className="d-flex form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="FirstName"
                    className="form-text form-control"
                    value={searchCriteria.firstName}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        firstName: e.target.value,
                      })
                    }
                    placeholder="Search by First Name"
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4">
                  <label htmlFor="LastName" className="d-flex form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="LastName"
                    className="form-text form-control"
                    value={searchCriteria.lastName}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        lastName: e.target.value,
                      })
                    }
                    placeholder="Search by Last Name"
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4">
                  <label htmlFor="Username" className="d-flex form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    name="Username"
                    className="form-text form-control"
                    value={searchCriteria.username}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        username: e.target.value,
                      })
                    }
                    placeholder="Search by Username"
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4">
                  <label htmlFor="Email" className="d-flex form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    name="Email"
                    className="form-text form-control"
                    value={searchCriteria.email}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        email: e.target.value,
                      })
                    }
                    placeholder="Search by Email"
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4">
                  <label htmlFor="IsActive" className="d-flex form-label">
                    Is Active
                  </label>
                  <div className="form-field position-relative">
                    <select
                      name="IsActive"
                      className="form-select form-control"
                      value={searchCriteria.isActive}
                      onChange={(e) => {
                        setSearchCriteria({
                          ...searchCriteria,
                          isActive: e.target.value,
                        });
                      }}
                    >
                      <option value="">All</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                    <span className="field-icon position-absolute top-50 translate-middle-y">
                      <i className="fh_arrow_down_line"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center">
                <button
                  type="button"
                  className="btn btn-primary h-48 me-3"
                  onClick={handleSearchSubmit}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-border-primary h-48"
                  onClick={handleClear}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="card-content">
              <div className="content-subheader d-flex align-items-center">
                <h2 className="page-subheader me-3">User List </h2>
                <span className="total-record">
                  [ Total Records: {totalRecords} ]
                </span>
              </div>
              <div className="table-responsive">
                <table {...getTableProps()} className="table caption-top">
                  <thead>
                    {headerGroups.length > 0 &&
                      headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th>{column.render("Header")}</th>
                          ))}
                        </tr>
                      ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {loading ? (
                      <tr>
                        <td
                          colSpan={columns.length}
                          className="text-center my-4"
                        >
                          <div className="spinner-border" role="status"></div>
                        </td>
                      </tr>
                    ) : data.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length} className="text-center">
                          No data found
                        </td>
                      </tr>
                    ) : (
                      page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {data && data.length > 0 && (
              <div className="d-flex justify-content-center">
                <nav
                  aria-label="Page navigation"
                  className="d-flex align-items-center"
                >
                  <span className="pagnination-info me-2">
                    Page{" "}
                    <strong>
                      {currentPage ? currentPage : 1} of {pageCount}
                    </strong>
                  </span>
                  <ul className="pagination d-flex justify-content-end mb-0">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={handleGoToFirstPage}
                        disabled={!hasPreviousPage}
                      >
                        <i className="fh_double_arrow_left"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={handlePrevious}
                        disabled={!hasPreviousPage}
                      >
                        <i className="fh_arrow_left_line"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={handleNext}
                        disabled={!hasNextPage}
                      >
                        <i className="fh_arrow_right_line"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={handleGoToLastPage}
                        disabled={!hasNextPage}
                      >
                        <i className="fh_double_arrow_right"></i>
                      </button>
                    </li>
                  </ul>
                </nav>
                <div>
                  <select
                    className="form-select form-control cursor-pointer custom-table-dropdown"
                    value={localPageSize}
                    onChange={(e) => {
                      handleChangePageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserSearch;