export function calculateAge(dob) {
  const dobDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - dobDate.getFullYear();
  const m = today.getMonth() - dobDate.getMonth();
  // If this year's birthday has not occurred yet, subtract one from age
  if (m < 0 || (m === 0 && today.getDate() < dobDate.getDate())) {
    age--;
  }
  return age;
}
// Function to convert strings to title case
export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
// Function to format dates
export const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);

  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const InputformatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const formatToMMDDYYYY = (isoDateString) => {
  if (typeof isoDateString !== "string") {
    console.error("Invalid date string:", isoDateString);
    return "Invalid date";
  }

  // Extract the date part (before 'T') from the ISO string
  const datePart = isoDateString.split("T")[0];

  // Split the date part into year, month, and day
  const [year, month, day] = datePart.split("-");

  // Format to MM/DD/YYYY
  return `${month}/${day}/${year}`;
};

export const UserAddDateToMMDDYYYY = (isoDateString) => {
  if (!isoDateString || typeof isoDateString !== "string") {
    return "Invalid date";
  }

  // Create a new Date object using the ISO string
  const date = new Date(isoDateString);

  // Extract the month, day, and year
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Format to MM/DD/YYYY
  return `${month}/${day}/${year}`;
};

// Function to format currency values as USD
export function formatCurrency(amount) {
  if (amount == null) {
    return "N/A";
  }

  // Assuming you want to format the number with two decimal places and commas
  // as thousands separators.
  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  return formattedAmount;
}

export function formatTime(timeString, formatType = "12") {
  const [hours, minutes, seconds] = timeString.split(":");
  let hoursInt = parseInt(hours, 10);
  let ampm = "";

  if (formatType === "12") {
    ampm = hoursInt >= 12 ? "PM" : "AM";
    hoursInt = hoursInt % 12 || 12; // Convert to 12h format, treating 0 as 12
  }
  // For a 24-hour format, hoursInt should not be modified or given AM/PM

  const formattedHours = (`0` + hoursInt).slice(-2); // Ensure two digits
  return `${formattedHours}:${minutes}:${seconds} ${ampm}`.trim();
}

export const getCurrentYear = () => {
  return new Date().getFullYear();
};